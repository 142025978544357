.scope-footer1-module {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    min-height: 439px
}

.scope-footer1-module .scope-footer1-module-center {
    flex: 1
}

.scope-footer1-module .scope-footer1-module-logo-container {
    padding: 97px 0 0 119px;
    text-align: center;
    text-decoration: none
}

.scope-footer1-module .scope-footer1-module-logo-container .scope-footer1-module-logo {
    margin-bottom: 15px;
    width: 170px
}

.scope-footer1-module .scope-footer1-module-logo-container .scope-footer1-module-logo-p1 {
    color: #424552;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .36px;
    line-height: 160%
}

.scope-footer1-module .scope-footer1-module-info-container {
    display: flex;
    padding: 109px 0 0 96px
}

.scope-footer1-module .scope-footer1-module-info-container .scope-footer1-module-info-ul {
    margin-right: 124px
}

.scope-footer1-module .scope-footer1-module-info-container .scope-footer1-module-info-ul:last-child {
    margin-right: 0
}

.scope-footer1-module .scope-footer1-module-info-container .scope-footer1-module-info-ul .scope-footer1-module-info-li1 {
    color: #424552;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.6px;
    margin-bottom: 24px
}

.scope-footer1-module .scope-footer1-module-info-container .scope-footer1-module-info-ul .scope-footer1-module-info-li2 {
    color: #686a6c;
    display: block;
    font-family: HarmonyOSHans-Light, PingFangSC-Regular, Microsoft YaHei, Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
    text-decoration: none;
}

.scope-footer1-module .scope-footer1-module-info-container .scope-footer1-module-info-ul .scope-footer1-module-info-li2:last-child {
    margin-bottom: 0
}

.scope-footer1-module .scope-footer1-module-qrcode-container {
    display: flex;
    padding: 50px 100px 100px 50px
}

.scope-footer1-module .scope-footer1-module-qrcode-container .scope-footer1-module-qrcode-img-container {
    margin-right: 46px
}

.scope-footer1-module .scope-footer1-module-qrcode-container .scope-footer1-module-qrcode-img-container:last-child {
    margin-right: 0
}

.scope-footer1-module .scope-footer1-module-qrcode-container .scope-footer1-module-qrcode-img-container .scope-footer1-module-qrcode-img {
    margin-bottom: 12px;
    width: 170px;
}

.scope-footer1-module .scope-footer1-module-qrcode-container .scope-footer1-module-qrcode-img-container .scope-footer1-module-qrcode-p1 {
    color: #686a6c;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    text-align: center
}

.scope-footer2-module {
    background-color: #37383a;
    padding: 20px
}

.scope-footer2-module .scope-footer2-module-p1 {
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    line-height: 20px;
    text-align: center
}

.scope-footer2-module .scope-footer2-module-p1 span {
    opacity: .8
}

.scope-footer2-module .scope-footer2-module-p1 a,
.scope-footer2-module .scope-footer2-module-p1 a:focus,
.scope-footer2-module .scope-footer2-module-p1 a:hover {
    color: #fff;
    cursor: pointer;
    opacity: .8;
    text-decoration: none
}

.scope-footer2-module .scope-footer2-module-p1 a:hover {
    opacity: 1
}

.scope-footer2-module .scope-footer2-module-p1 .scope-footer2-module-p1-divider {
    background-color: #fff;
    display: inline-block;
    height: 1em;
    margin: 0 8px;
    opacity: .8;
    position: relative;
    vertical-align: middle;
    width: 1px
}
